import { createSelector } from "reselect";
import { produce } from "immer";
import sortBy from "lodash/sortBy";

import { traverseListsAndRemoveEmpty, searchTree } from "./lib/tree-helpers";
import { USER_LOGOUT } from "../me";
import createReducer from "../../store/create-reducer";
import makeActionCreator from "../../store/create-action";

const initialState = {
  all: [],
  groups: [],
  fetchedAll: false,
  fetchedGroups: false
};

const SET_LISTS = "SET_LISTS";
const ADD_LIST = "ADD_LIST";
const DELETE_LIST = "DELETE_LIST";
const CHANGE_LIST_NAME = "CHANGE_LIST_NAME";
const SET_LISTS_GROUPS = "SET_LISTS_GROUPS";
const ADD_LISTS_GROUP = "ADD_LISTS_GROUP";
const UPDATE_LISTS_GROUP = "UPDATE_LISTS_GROUP";
const DELETE_LISTS_GROUP = "DELETE_LISTS_GROUP";

export const setLists = makeActionCreator(SET_LISTS, "lists");
export const addList = makeActionCreator(ADD_LIST, "list", "parent");
export const deleteList = makeActionCreator(DELETE_LIST, "id", "parentId");
export const updateList = makeActionCreator(CHANGE_LIST_NAME, "list");
export const setListGroups = makeActionCreator(SET_LISTS_GROUPS, "groups");
export const addListGroup = makeActionCreator(ADD_LISTS_GROUP, "group");
export const updateListGroup = makeActionCreator(
  UPDATE_LISTS_GROUP,
  "id",
  "label"
);
export const deleteListGroup = makeActionCreator(DELETE_LISTS_GROUP, "id");

export const reducer = createReducer(initialState, {
  [USER_LOGOUT]: () => initialState,
  [SET_LISTS]: (state, action) => ({
    ...state,
    fetchedAll: true,
    all: action.lists
  }),
  [ADD_LIST]: (state, action) =>
    produce(state, draft => {
      action.list.children = [];

      // Ajout d'une liste racine
      if (action.parent === null) {
        draft.all.push(action.list);
        return;
      }

      let parent = null;
      for (const node of draft.all) {
        const n = searchTree(action.parent.id, node);
        console.log(n);
        if (n) {
          parent = n;
          break;
        }
      }

      if (!parent.children) parent.children = [action.list];
      else parent.children.push(action.list);
    }),
  [CHANGE_LIST_NAME]: (state, action) =>
    produce(state, draft => {
      let list = null;
      for (const node of draft.all) {
        const n = searchTree(action.list.id, node);
        console.log(n);
        if (n) {
          list = n;
          break;
        }
      }

      if (list) list.name = action.list.name;
    }),
  [DELETE_LIST]: (state, action) =>
    produce(state, draft => {
      // liste racine
      if (action.parentId === null) {
        const index = draft.all.findIndex(list => list.id === action.id);
        if (index !== -1) {
          draft.all.splice(index, 1);
        }
        return;
      }

      let parent = null;
      for (const node of draft.all) {
        const n = searchTree(action.parentId, node);
        console.log(n);
        if (n) {
          parent = n;
          break;
        }
      }

      if (parent) {
        const index = parent.children.findIndex(list => list.id === action.id);
        if (index !== -1) {
          parent.children.splice(index, 1);
        }
      }
    }),
  [SET_LISTS_GROUPS]: (state, action) => ({
    ...state,
    fetchedGroups: true,
    groups: action.groups
  }),
  [ADD_LISTS_GROUP]: (state, action) =>
    produce(state, draft => {
      draft.groups.push(action.group);
    }),
  [UPDATE_LISTS_GROUP]: (state, action) =>
    produce(state, draft => {
      const item = draft.groups.find(g => g.id === action.id);

      if (item !== -1) {
        item.label = action.label;
      }
    }),
  [DELETE_LISTS_GROUP]: (state, action) =>
    produce(state, draft => {
      const startIndex = draft.groups.findIndex(g => g.id === action.id);

      const length = draft.groups.length;
      for (let i = startIndex; i < length; i++) {
        draft.groups[i] = null;
      }
      draft.groups = draft.groups.filter(g => g);

      function rDelete(cpt, node) {
        if (cpt === startIndex) {
          delete node.children;
          return;
        }

        for (const n of node.children) {
          rDelete(cpt + 1, n);
        }
      }

      if (startIndex === 0) {
        draft.all = [];
      } else {
        for (const node of draft.all) {
          rDelete(1, node);
        }
      }
    })
});

export const getLists = state => state.lists;
export const getListsData = createSelector(
  [getLists],
  lists => traverseListsAndRemoveEmpty(lists.all)
);
export const isListsFetched = createSelector(
  [getLists],
  lists => lists.fetchedAll
);

export const getListGroups = createSelector(
  [getLists],
  lists => sortBy(lists.groups, ["level"])
);
export const isListsFetchedGroups = createSelector(
  [getLists],
  lists => lists.fetchedGroups
);
export const getListGroupsLength = createSelector(
  [getListGroups],
  groups => groups.length
);
