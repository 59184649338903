import React from "react";
import { Form, Input, Switch, Icon, Radio, Divider, Button } from "antd";

import SideFormFooter from "../../../components/form/sideform-footer";

class MyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayChoiceFormPart:
        props.mode === "edit"
          ? this.displayChoiceFormPart(props.field.type)
          : false
    };

    this.keyId =
      props.mode === "edit" && props.field.items ? props.field.items.length : 0;
  }

  displayChoiceFormPart = valueToTest =>
    ["choice", "multiple-choice"].indexOf(valueToTest) !== -1;

  handleSubmit = e => {
    e.preventDefault();
    if (this.props.loading) return;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!values.items) values.items = [];
        delete values.keys;

        this.props.mode === "edit"
          ? this.props.updateField(values, this.props.field.id)
          : this.props.createField(values);
      }
    });
  };

  handleChangeType = e =>
    this.setState({
      displayChoiceFormPart: this.displayChoiceFormPart(e.target.value)
    });

  addChoice = () => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");

    form.setFieldsValue({
      keys: keys.concat(++this.keyId)
    });
  };

  removeChoice = k => {
    const { form } = this.props;
    const keys = form.getFieldValue("keys");

    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  render() {
    const {
      mode,
      field,
      form: { getFieldDecorator, getFieldValue }
    } = this.props;

    getFieldDecorator("keys", {
      initialValue:
        mode === "edit" && field.items ? field.items.map((i, idx) => idx) : []
    });

    const keys = getFieldValue("keys");

    return (
      <Form onSubmit={this.handleSubmit} style={{ paddingBottom: 108 }}>
        <Form.Item label="Nom" hasFeedback>
          {getFieldDecorator("name", {
            initialValue: mode === "edit" ? field.name : undefined,
            rules: [{ required: true, message: "Veuillez renseigner un nom" }]
          })(<Input autoFocus />)}
        </Form.Item>
        <Form.Item label="Activé">
          {getFieldDecorator("enabled", {
            valuePropName: "checked",
            initialValue: mode === "edit" ? field.enabled : true,
            rules: [{ required: true }]
          })(
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="cross" />}
            />
          )}
        </Form.Item>
        <Form.Item label="Requis">
          {getFieldDecorator("required", {
            valuePropName: "checked",
            initialValue: mode === "edit" ? field.required : false,
            rules: [{ required: true }]
          })(
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="cross" />}
            />
          )}
        </Form.Item>
        <Form.Item label="Type de champ">
          {getFieldDecorator("type", {
            initialValue: mode === "edit" ? field.type : "text",
            rules: [{ required: true }]
          })(
            <Radio.Group onChange={this.handleChangeType}>
              <Radio value="text">Texte</Radio>
              <Radio value="choice">Choix</Radio>
              <Radio value="multiple-choice">Choix multiple</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {this.state.displayChoiceFormPart && (
          <>
            <Divider>Choix</Divider>
            {keys.map(k => (
              <Form.Item required={false} key={k}>
                {getFieldDecorator(`items[${k}]`, {
                  initialValue:
                    mode === "edit" && field.items[k]
                      ? field.items[k].name
                      : undefined,
                  validateTrigger: ["onChange", "onBlur"],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Veuillez entrer un nom pour ce choix"
                    }
                  ]
                })(
                  <Input
                    placeholder="Nom du choix"
                    style={{ width: "90%", marginRight: "1rem" }}
                  />
                )}
                {keys.length > 1 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    disabled={keys.length === 1}
                    onClick={() => this.removeChoice(k)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Button
              icon="plus"
              type="primary"
              ghost
              block
              onClick={this.addChoice}
            >
              Ajouter un choix
            </Button>
          </>
        )}

        <SideFormFooter
          loading={this.props.loading}
          cancel={this.props.cancel}
        />
      </Form>
    );
  }
}

export default Form.create()(MyForm);
