import { createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";

import rootReducer from "./root-reducer";

export default function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, devToolsEnhancer());

  return store;
}
