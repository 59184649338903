import React from "react";
import { Exception } from "ant-design-pro";

export default class GlobalErrorBoundary extends React.PureComponent {
  state = {
    error: null
  };

  componentDidCatch(error) {
    this.setState({ error: true });
    console.error(error);
  }

  render() {
    if (this.state.error)
      return (
        <Exception
          type="500"
          desc="Une erreur non identifée est survenue."
          backText="Retour à l'accueil"
        />
      );

    return this.props.children;
  }
}
