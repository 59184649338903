import React from "react";
import { connect } from "react-redux";

import { setBreadcrumb } from "../reducer";

class Feeder extends React.Component {
  componentDidMount() {
    this.props.setBreadcrumb(this.props.paths);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paths !== this.props.paths)
      this.props.setBreadcrumb(this.props.paths);
  }

  componentWillUnmount() {
    this.props.setBreadcrumb([]);
  }

  render() {
    return null;
  }
}

export default connect(
  null,
  { setBreadcrumb }
)(Feeder);
