import { redirect, map } from "navi";

import { roles } from "../lib/constants";

export const makeTitle = title => `${title} | 2J Mobile`;

export const makeRoleStr = role => {
  switch (role) {
    case roles.Learner:
      return "Apprenants";
    case roles.Tutor:
      return "Tuteurs";
    case roles.Admin:
      return "Administrateurs";
    default:
      return "";
  }
};

export const withAuthentication = matcher =>
  map((request, context) =>
    context.currentUser
      ? matcher
      : redirect(
          "/login?redirectTo=" +
            encodeURIComponent(request.mountpath + request.search)
        )
  );
