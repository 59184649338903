import React, { useState, useEffect, Suspense } from "react";
import {
  Link,
  Router,
  View,
  NotFoundBoundary,
  useLoadingRoute
} from "react-navi";
import BusyIndicator from "react-busy-indicator";
import { Exception } from "ant-design-pro";

import { authService } from "./features/me";
import routes from "./routes";

function App() {
  const [currentUser, setCurrentUser] = useState(() =>
    authService.getCurrentUser()
  );

  useEffect(() => authService.subscribe(setCurrentUser), [currentUser]);

  return (
    <Router routes={routes} context={{ currentUser }}>
      <TopProgressBar />
      <NotFoundBoundary render={renderNotFound}>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </NotFoundBoundary>
    </Router>
  );
}

function renderNotFound() {
  return (
    <Exception
      linkElement={Link}
      backText="Retour à l'accueil"
      desc="Page non trouvée"
      type="404"
    />
  );
}

function TopProgressBar() {
  const loadingRoute = useLoadingRoute();

  return <BusyIndicator isBusy={!!loadingRoute} delayMs={200} />;
}

export default App;
