import React from "react";
import { connect } from "react-redux";
import { Menu, Icon, Dropdown, Avatar } from "antd";

import "./styles.css";
import { selectMe } from "../reducer";
import authService from "../services/auth";

function Profile({ me }) {
  if (!me) return null;

  const menu = (
    <Menu>
      <Menu.Item key="userinfo">
        <span>Mon profil</span>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="logout" onClick={authService.signOut}>
        <Icon type="logout" />
        <span>Se déconnecter</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <span className="profile">
        <Avatar icon="user" size="small" />
        <span className="profile-title">
          {me.user.firstname} {me.user.lastname}
        </span>
      </span>
    </Dropdown>
  );
}

export default connect(state => ({ me: selectMe(state) }))(React.memo(Profile));
