/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import { Button, List, Popconfirm, Drawer, message } from "antd";

import LevelsForm from "./levels-form";
import {
  getListGroups,
  addListGroup,
  deleteListGroup,
  updateListGroup
} from "../reducer";
import api from "../../../lib/api";

class Levels extends React.PureComponent {
  state = {
    drawerOpened: false,
    loading: false
  };

  safeSetState = obj => {
    if (!this._mounted) return;

    this.setState(obj);
  };

  open = () => this.setState({ drawerOpened: true, mode: "add" });
  edit = level => this.setState({ drawerOpened: true, mode: "edit", level });
  close = () =>
    this.safeSetState({
      drawerOpened: false,
      loading: false
    });

  create = level => {
    this.setState({ loading: true });

    api.post("/lists/groups", level).then(response => {
      if (response.ok) {
        message.success(
          `Niveau "${response.data.label}" ajouté à l'arborescence.`
        );
        this.props.addListGroup(response.data);
        this.close();
      }
    });
  };

  update = level => {
    this.setState({ loading: true });

    api.patch(`/lists/groups/${this.state.level.id}`, level).then(response => {
      if (response.ok) {
        this.props.updateListGroup(this.state.level.id, level.label);
        this.close();
      }
    });
  };

  delete = level => {
    api.delete(`/lists/groups/${level.id}`).then(response => {
      if (response.ok) {
        message.success(`Niveau "${level.label}" supprimé de l'arborescence.`);
        this.props.deleteListGroup(level.id);
      }
    });
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return (
      <div className="levels">
        <h3>Niveaux</h3>
        <Button type="primary" onClick={this.open} icon="plus">
          Ajouter un niveau à l'arborescence
        </Button>
        <List
          style={{ marginTop: "1rem" }}
          dataSource={this.props.levels}
          itemLayout="horizontal"
          renderItem={item => (
            <List.Item
              key={item.id}
              actions={[
                <a onClick={() => this.edit(item)}>Modifier</a>,
                <Popconfirm
                  title={<PopTitle />}
                  okText="Oui"
                  onConfirm={() => this.delete(item)}
                >
                  <a className="error-link">Supprimer</a>
                </Popconfirm>
              ]}
            >
              <List.Item.Meta
                description={"Niveau " + item.level}
                title={item.label}
              />
            </List.Item>
          )}
        />
        <Drawer
          destroyOnClose
          title={
            this.state.mode === "add"
              ? "Ajout d'un niveau"
              : "Modifier le niveau"
          }
          width={500}
          onClose={this.close}
          visible={this.state.drawerOpened}
        >
          <LevelsForm
            mode={this.state.mode}
            level={this.state.level}
            loading={this.state.loading}
            cancel={this.close}
            create={this.create}
            update={this.update}
          />
        </Drawer>
      </div>
    );
  }
}

const PopTitle = () => (
  <>
    <p>Êtes-vous sur de vouloir supprimer ce niveau ?</p>
    <span>
      Toute la partie de l'arborescence correspondante <br />
      sera supprimée.
    </span>
  </>
);

export default connect(
  state => ({
    levels: getListGroups(state)
  }),
  { addListGroup, deleteListGroup, updateListGroup }
)(Levels);
