import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { LocaleProvider } from "antd";
import frFR from "antd/lib/locale-provider/fr_FR";
import moment from "moment";
import "moment/locale/fr";

import "./global-styles.css";
import App from "./app";
import configureStore from "./store/configure-store";
import GlobalErrorBoundary from "./components/global-error-boundary";
import { authService } from "./features/me";

moment.locale("fr");

const store = configureStore();

authService.injectDependencies({ store });
authService.hydrateUserFromCookie();

console.log("Version " + process.env.REACT_APP_VERSION);

ReactDOM.render(
  <LocaleProvider locale={frFR}>
    <Provider store={store}>
      <GlobalErrorBoundary>
        <App />
      </GlobalErrorBoundary>
    </Provider>
  </LocaleProvider>,
  document.getElementById("root")
);
