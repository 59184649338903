import React from "react";
import { connect } from "react-redux";
import { Breadcrumb } from "antd";

import { getPaths } from "../reducer";

class MyBreadcrumb extends React.Component {
  render() {
    if (!this.props.breadcrumb) return null;

    return (
      <Breadcrumb style={{ marginBottom: 8 }}>
        {this.props.breadcrumb.map((item, index) => (
          <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
}

export default connect(state => ({ breadcrumb: getPaths(state) }))(
  MyBreadcrumb
);
