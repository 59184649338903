import makeActionCreator from "../../store/create-action";
import createReducer from "../../store/create-reducer";

const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";

export const userLoginSuccess = makeActionCreator(USER_LOGIN_SUCCESS, "user");
export const userLogout = makeActionCreator(USER_LOGOUT);
export const refreshToken = makeActionCreator(USER_REFRESH_TOKEN, "access");

export const reducer = createReducer(null, {
  [USER_LOGIN_SUCCESS]: (state, action) => action.user,
  [USER_LOGOUT]: (state, action) => null,
  [USER_REFRESH_TOKEN]: (state, action) => action.access
});

export const selectMe = state => state.me;
