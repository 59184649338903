import { combineReducers } from "redux";

import { reducer as meReducer } from "../features/me";
import { reducer as listReducer } from "../features/lists";
import { reducer as extrafieldReducer } from "../features/extrafields";
import { reducer as navReducer } from "../features/nav";

const rootReducer = combineReducers({
  breadcrumb: navReducer,
  me: meReducer,
  lists: listReducer,
  extrafields: extrafieldReducer
});

export default rootReducer;
