import React from "react";
import { mount, route, lazy, map, redirect } from "navi";

import { makeTitle } from "./helpers";
import { roles } from "../lib/constants";

const LoginPage = React.lazy(() => import("./pages/login"));

export default mount({
  "/": map(async (request, context) => {
    if (!context.currentUser) {
      return redirect("/login");
    }

    switch (context.currentUser.user.role) {
      case roles.SuperAdmin:
        return redirect("/2j");
      case roles.Tutor:
        return redirect("/trainer");
      case roles.Admin:
        return redirect("/admin");
      default:
        return redirect("/404");
    }
  }),
  "/login": map(async (request, context) =>
    context.currentUser
      ? redirect(
          request.params.redirectTo
            ? decodeURIComponent(request.params.redirectTo)
            : "/"
        )
      : route({
          title: makeTitle("Connexion"),
          view: <LoginPage />
        })
  ),

  "/2j": lazy(() => import("./2j")),
  "/admin": lazy(() => import("./admin")),
  "/trainer": lazy(() => import("./trainer"))
});

// import React from "react";
// import { createPage, createSwitch, createRedirect } from "navi";
// import { Exception } from "ant-design-pro";

// import { roles } from "../lib/constants";
// import { authenticated } from "../lib/guard";
// import api from "../lib/api";
// import AuthLayout from "../components/layouts/auth-layout";

// const UserPage = React.lazy(() => import("./user"));
// const LoginPage = React.lazy(() => import("./login"));
// const ReportingPage = React.lazy(() => import("./reporting"));

// export default createSwitch({
//   paths: {
//     "/": env => {
//       if (!env.context.currentUser) {
//         return createRedirect("/login");
//       }

//       switch (env.context.currentUser.user.role) {
//         case roles.SuperAdmin:
//           return createRedirect("/2j");
//         case roles.Tutor:
//           return createPage({
//             content: (
//               <Exception
//                 type="403"
//                 title="WIP"
//                 backText="Retour à l'accueil"
//                 desc=" "
//               />
//             )
//           });
//         case roles.Admin:
//           return createRedirect("/a");
//         default:
//           return createRedirect("/404");
//       }
//     },

//     "/login": env => {
//       // Si on a un utilisateur on bypass le login
//       if (env.context.currentUser)
//         return createRedirect(
//           env.params.redirectTo
//             ? decodeURIComponent(env.params.redirectTo)
//             : "/"
//         );

//       return createPage({
//         title: makeTitle("Connexion"),
//         content: <LoginPage />
//       });
//     },

//     "/2j": createPage({
//       title: makeTitle("2J Administration"),
//       content: (
//         <Exception
//           type="403"
//           title="WIP"
//           backText="Retour à l'accueil"
//           desc=" "
//         />
//       )
//     }),

//     "/a": createSwitch({
//       content: <AuthLayout />,

//       paths: {
//         "/users": createSwitch({
//           paths: {
//             "/learner": authenticated(
//               createPage({
//                 title: makeTitle("Gestion des apprenants"),
//                 content: <UserPage role={roles.Learner} />
//               }),
//               [roles.Admin, roles.Tutor]
//             ),

//             "/trainer": authenticated(
//               createPage({
//                 title: makeTitle("Gestion des tuteurs"),
//                 content: <UserPage role={roles.Tutor} />
//               }),
//               [roles.Admin]
//             ),

//             "/admin": authenticated(
//               createPage({
//                 title: makeTitle("Gestion des administrateurs"),
//                 content: <UserPage role={roles.Admin} />
//               }),
//               [roles.SuperAdmin]
//             ),

//             "/": createRedirect("./learner")
//           }
//         }),

//         "/reporting": authenticated(
//           createPage({
//             title: makeTitle("Reporting"),
//             getContent: async () => {
//               const response = await api.get("/lessons");

//               return (
//                 <ReportingPage lessons={response.ok ? response.data : null} />
//               );
//             }
//           }),
//           [roles.Admin]
//         ),

//         "/settings": createSwitch({
//           paths: {
//             "/extrafields": authenticated(
//               createPage({
//                 title: makeTitle("Champs supplémentaires"),
//                 getContent: () => import("./settings/extrafields")
//               }),
//               [roles.Admin]
//             ),

//             "/lists": authenticated(
//               createPage({
//                 title: makeTitle("Arborescences"),
//                 getContent: () => import("./settings/lists")
//               }),
//               [roles.Admin]
//             ),

//             "/": createRedirect("./extrafields")
//           }
//         }),

//         "/": createRedirect("./users")
//       }
//     })
//   }
// });
