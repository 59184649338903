export const traverseListsAndRemoveEmpty = lists =>
  lists.map(list => traverseCopy(list));

function traverseCopy(node) {
  if (!node.children || node.children.length === 0) {
    const copy = { ...node };
    delete copy.children;

    return copy;
  }

  return { ...node, children: node.children.map(l => traverseCopy(l)) };
}

function traverseForSearch(node, predicateFn, depth) {
  if (predicateFn(node, depth)) return node;

  if (node.children.length) {
    for (const n of node.children) {
      const r = traverseForSearch(n, predicateFn, depth + 1);
      if (r) {
        return r;
      }
    }
  }
}

export function searchTree(id, root) {
  console.log("-----start-----", id);
  return traverseForSearch(
    root,
    node => console.log(node.id) || node.id === id,
    0
  );
}
