import React from "react";
import { Divider } from "antd";

import "./styles.css";
import Levels from "./levels";
import Tree from "./tree";
import {
  isListsFetched,
  isListsFetchedGroups,
  setListGroups,
  setLists
} from "../reducer";
import Prefetch from "../../../components/prefetch";
import { DelayedSpinner } from "../../../components/loading";

export default class Lists extends React.PureComponent {
  render() {
    return (
      <Prefetch
        ressources={[
          {
            url: "/lists",
            fetched: isListsFetched,
            setter: setLists
          },
          {
            url: "/lists/groups",
            fetched: isListsFetchedGroups,
            setter: setListGroups
          }
        ]}
        fallback={<DelayedSpinner />}
      >
        <div className="lists pane">
          <Levels />
          <Divider type="vertical" className="full-vertical-divider" />
          <Tree />
        </div>
      </Prefetch>
    );
  }
}
