import React from "react";
import { Form, Input } from "antd";

import SideFormFooter from "../../../components/form/sideform-footer";

class LevelsForm extends React.PureComponent {
  handleSubmit = e => {
    e.preventDefault();
    if (this.props.loading) return;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.mode === "add"
          ? this.props.create(values)
          : this.props.update(values);
      }
    });
  };

  render() {
    const {
      mode,
      level,
      form: { getFieldDecorator }
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="Nom" hasFeedback>
          {getFieldDecorator("label", {
            initialValue: mode === "edit" ? level.label : undefined,
            rules: [{ required: true, message: "Veuillez renseigner un nom" }]
          })(<Input autoFocus />)}
        </Form.Item>

        <SideFormFooter
          loading={this.props.loading}
          cancel={this.props.cancel}
        />
      </Form>
    );
  }
}

export default Form.create()(LevelsForm);
