import React from "react";

import Spinner from "./spinner";

export default class DelayedSpinner extends React.PureComponent {
  static defaultProps = {
    delay: 1 * 1000
  };

  state = {
    showLoader: false
  };
  timeout = null;

  initTimeout = () => {
    this.clearTimeout();

    this.timeout = setTimeout(
      () => this.setState({ showLoader: true }),
      this.props.delay
    );
  };

  clearTimeout = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  componentDidMount() {
    this.initTimeout();
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  render() {
    if (this.state.showLoader) return <Spinner />;

    return null;
  }
}
