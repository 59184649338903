import makeActionCreator from "../../store/create-action";
import createReducer from "../../store/create-reducer";

const SET_BREADCRUMB = "SET_BREADCRUMB";

export const setBreadcrumb = makeActionCreator(SET_BREADCRUMB, "paths");

export const reducer = createReducer([], {
  [SET_BREADCRUMB]: (state, action) => action.paths
});

export const getPaths = state => state.breadcrumb;
