import Cookie from "js-cookie";

import { userLoginSuccess, userLogout, refreshToken } from "../reducer";
import api from "../../../lib/api";
import { roles } from "../../../lib/constants";

const COOKIE = "2j-mobile-me";
let subId = 1;

class AuthService {
  constructor() {
    this.initialState = { currentUser: null };

    this.state = { ...this.initialState };
    this.subscribers = new Map();

    this.refreshingToken = false;

    this.signOut = this.signOut.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  setState(newState, notify = true) {
    this.state = { ...this.state, ...newState };

    // notify subscribers
    if (notify) {
      for (const sub of this.subscribers.values()) {
        sub(this.state.currentUser);
      }
    }
  }

  injectDependencies(dependencies) {
    Object.keys(dependencies).forEach(key =>
      this.injectDependency(key, dependencies[key])
    );
  }

  injectDependency(key, dependency) {
    this[key] = dependency;
  }

  verifyDependencies() {
    if (!this.store)
      throw new Error(
        `Veuillez utiliser la méthode "injectDependency" pour injecter le store redux`
      );
  }

  subscribe(fn) {
    if (typeof fn !== "function") {
      throw new Error("Subscriber doit être une fonction");
    }

    this.subscribers.set(subId, fn);
    const id = subId;
    subId++;

    return () => {
      this.subscribers.delete(id);
    };
  }

  patchGlobalApi(access) {
    // si pas SUPER_ADMIN on renseigne le client dans l'api
    if (access.user.role !== roles.SuperAdmin)
      api.setHeader("x-api-clientid", access.user.client.identifier);

    api.setHeader("Authorization", `bearer ${access.token}`);
  }

  hydrateUserFromCookie() {
    this.verifyDependencies();

    let access = Cookie.get(COOKIE);
    if (!access) return;

    access = JSON.parse(access);
    if (!access.token || this.isTokenExpired(access.token)) return;

    this.patchGlobalApi(access);
    this.setState({ authenticated: true, currentUser: access });
    this.store.dispatch(userLoginSuccess(access));
  }

  getCurrentUser() {
    return this.state.currentUser;
  }

  signIn(access) {
    this.verifyDependencies();

    this.patchGlobalApi(access);

    Cookie.set(COOKIE, access, {
      secure: process.env.NODE_ENV === "production"
    });
    this.store.dispatch(userLoginSuccess(access));
    this.setState({ authenticated: true, currentUser: access });
  }

  signOut() {
    this.verifyDependencies();

    Cookie.remove(COOKIE);
    this.store.dispatch(userLogout());

    this.setState(this.initialState);
  }

  isTokenExpired(token) {
    const tokenArray = token.split(".");
    if (!tokenArray[1]) return true;

    const payload = JSON.parse(window.atob(tokenArray[1]));
    const dateExpiration = payload.exp;
    const dateCurrent = Math.floor(Date.now() / 1000);

    return dateCurrent >= dateExpiration;
  }

  isTokenSoonExpired(token) {
    const tokenArray = token.split(".");
    if (!tokenArray[1]) return false;

    const payload = JSON.parse(window.atob(tokenArray[1]));
    const dateExpiration = payload.exp;
    const dateCurrent = Math.floor(Date.now() / 1000);
    const secondBeforeExpired = dateExpiration - dateCurrent;

    return secondBeforeExpired < 1800;
  }

  checkToken() {
    const access = this.getCurrentUser();
    if (!access || this.refreshingToken) return;

    if (this.isTokenSoonExpired(access.token)) {
      this.refreshingToken = true;

      api.get("/auth/renew").then(response => {
        if (response.ok) {
          const newAccess = response.data.access_token;

          this.setState(
            {
              currentUser: newAccess
            },
            false
          );
          this.store.dispatch(refreshToken(newAccess));
        }
        this.refreshingToken = false;
      });
    }
  }
}

export default new AuthService();
