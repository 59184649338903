import React from "react";
import { connect } from "react-redux";
import { Table, Tag, Divider, Popconfirm, notification } from "antd";

import AskForDelete from "./ask-delete";
import {
  getExtrafieldsData,
  toggleExtrafield,
  deleteExtrafield
} from "../reducer";
import api from "../../../lib/api";

class ExtrafieldList extends React.PureComponent {
  columnsDef = [
    {
      title: "Nom",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Activé",
      dataIndex: "enabled",
      key: "enabled",
      width: 120,
      render: enabled => (
        <Tag color={enabled ? "green" : "red"}>
          {enabled ? "Activé" : "Désactivé"}
        </Tag>
      )
    },
    {
      title: "Requis",
      dataIndex: "required",
      key: "required",
      width: 120,
      render: required => required && <Tag color="blue">Requis</Tag>
    },
    {
      title: "Actions",
      width: 275,
      render: (value, record) => (
        <span>
          {record.enabled ? (
            <Popconfirm
              title="Voulez-vous vraiment désactiver ce champ ?"
              okText="Oui"
              onConfirm={() => this.toggle(record.id, "disable")}
            >
              {/* eslint-disable-next-line */}
              <a>Désactiver</a>
            </Popconfirm>
          ) : (
            /* eslint-disable-next-line */
            <a onClick={() => this.toggle(record.id, "enable")}>Activer</a>
          )}
          <Divider type="vertical" />
          {/* eslint-disable-next-line */}
          <a onClick={() => this.startEdit(record)}>Modifier</a>
          <Divider type="vertical" />

          <Popconfirm
            placement="left"
            title="Êtes-vous sur de vouloir supprimer ce champ ?"
            okText="Oui"
            onConfirm={() => this.delete(record.id)}
          >
            {/* eslint-disable-next-line */}
            <a className="error-link">Supprimer</a>
          </Popconfirm>
        </span>
      )
    }
  ];

  startEdit = field => {
    this.props.edit(field);
  };
  delete = id => {
    api.delete(`/client/extrafields/${id}`).then(response => {
      if (response.ok) {
        if (response.data.askForDelete)
          notification.warning({
            key: "ask-for-delete",
            duration: null,
            message: "Attention!",
            description: (
              <AskForDelete
                id={id}
                deleteCallback={this.props.deleteExtrafield}
              />
            )
          });
        else this.props.deleteExtrafield(id);
      }
    });
  };
  toggle = (id, op) => {
    api.put(`/client/extrafields/${id}/${op}`).then(response => {
      if (response.ok) {
        this.props.toggleExtrafield(op, id);
      }
    });
  };

  render() {
    return (
      <Table
        columns={this.columnsDef}
        dataSource={this.props.fields}
        rowKey="id"
        style={{ marginTop: "1rem" }}
        pagination={false}
      />
    );
  }
}

export default connect(
  state => ({ fields: getExtrafieldsData(state) }),
  { toggleExtrafield, deleteExtrafield }
)(ExtrafieldList);
