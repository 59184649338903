import React from "react";
import { Form, Input, Button, Modal } from "antd";

import SideFormFooter from "../../../components/form/sideform-footer";

const confirm = Modal.confirm;

class TreeForm extends React.PureComponent {
  handleSubmit = e => {
    e.preventDefault();
    if (this.props.loading) return;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.name = values.element;
        delete values.element;

        this.props.mode === "edit"
          ? this.props.update(values)
          : this.props.create(values);
      }
    });
  };

  askForDelete = () => {
    confirm({
      title: "Voulez-vous vraiment supprimer cet élément ?",
      content:
        "La partie de l'arborescence liée à cet élement sera également supprimée.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk: () => this.props.delete()
    });
  };

  render() {
    const {
      mode,
      list,
      form: { getFieldDecorator }
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="Élément" hasFeedback>
          {getFieldDecorator("element", {
            initialValue: mode === "edit" ? list.name : undefined,
            rules: [
              {
                required: true,
                message: "Veuillez donner une valeur à l'élément"
              }
            ]
          })(<Input autoFocus />)}
        </Form.Item>

        {mode === "edit" && (
          <Button type="danger" block onClick={this.askForDelete}>
            Supprimer
          </Button>
        )}

        <SideFormFooter
          loading={this.props.loading}
          cancel={this.props.cancel}
        />
      </Form>
    );
  }
}
export default Form.create()(TreeForm);
