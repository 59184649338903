import { createSelector } from "reselect";
import { produce } from "immer";

import { USER_LOGOUT } from "../me";
import createReducer from "../../store/create-reducer";
import makeActionCreator from "../../store/create-action";

const initialState = {
  all: [],
  fetched: false
};

const SET_EXTRA = "SET_EXTRA";
const ADD_EXTRA = "ADD_EXTRA";
const TOGGLE_EXTRA = "TOGGLE_EXTRA";
const DELETE_EXTRA = "DELETE_EXTRA";
const UPDATE_EXTRA = "UPDATE_EXTRA";

export const setExtrafields = makeActionCreator(SET_EXTRA, "extrafields");
export const addExtrafield = makeActionCreator(ADD_EXTRA, "extrafield");
export const toggleExtrafield = makeActionCreator(TOGGLE_EXTRA, "op", "id");
export const deleteExtrafield = makeActionCreator(DELETE_EXTRA, "id");
export const updateExtrafield = makeActionCreator(
  UPDATE_EXTRA,
  "id",
  "extrafield"
);

export const reducer = createReducer(initialState, {
  [USER_LOGOUT]: () => initialState,
  [SET_EXTRA]: (state, action) => ({
    fetched: true,
    all: action.extrafields
  }),
  [ADD_EXTRA]: (state, action) =>
    produce(state, draft => {
      draft.all.push(action.extrafield);
    }),
  [TOGGLE_EXTRA]: (state, action) =>
    produce(state, draft => {
      const extra = draft.all.find(e => e.id === action.id);

      if (extra) {
        extra.enabled = action.op === "enable" ? true : false;
      }
    }),
  [DELETE_EXTRA]: (state, action) =>
    produce(state, draft => {
      const index = draft.all.findIndex(e => e.id === action.id);

      if (index !== -1) {
        draft.all.splice(index, 1);
      }
    }),
  [UPDATE_EXTRA]: (state, action) =>
    produce(state, draft => {
      const index = draft.all.findIndex(e => e.id === action.id);

      if (index !== -1) {
        draft.all[index] = action.extrafield;
      }
    })
});

export const getExtrafields = state => state.extrafields;
export const getExtrafieldsData = createSelector(
  [getExtrafields],
  extrafields => extrafields.all
);
export const getEnabledExtrafields = createSelector(
  [getExtrafields],
  extrafields => extrafields.all.filter(e => e.enabled)
);

export const isExtrafieldsFetched = createSelector(
  [getExtrafields],
  extrafields => extrafields.fetched
);
