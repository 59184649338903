import React from "react";
import { connect } from "react-redux";
import { Cascader } from "antd";

import { getListsData } from "../reducer";

const filter = (inputValue, path) =>
  path.some(
    option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );

function ComboBox({
  value,
  lists,
  onChange,
  changeOnSelect,
  dispatch,
  ...props
}) {
  return (
    <Cascader
      value={value}
      placeholder="Arborescence"
      options={lists}
      onChange={onChange}
      changeOnSelect={changeOnSelect}
      fieldNames={{ label: "name", value: "id", children: "children" }}
      showSearch={{ filter }}
      {...props}
    />
  );
}

ComboBox.defaultProps = {
  changeOnSelect: false,
  onChange: () => {}
};

export default connect(state => ({ lists: getListsData(state) }))(
  React.memo(ComboBox)
);
