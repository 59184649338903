import React from "react";
import { connect } from "react-redux";
import { Drawer, Button, message, notification } from "antd";

import "./styles.css";
import ExtrafieldList from "./list";
import Form from "./form";
import { addExtrafield, updateExtrafield } from "../reducer";
import { DelayedSpinner } from "../../../components/loading";
import Prefetch from "../../../components/prefetch";
import {
  setExtrafields,
  isExtrafieldsFetched
} from "../../../features/extrafields";
import api from "../../../lib/api";

class Extrafields extends React.PureComponent {
  initialState = {
    drawerOpened: false,
    loading: false
  };

  state = { ...this.initialState, mode: null };
  safeSetState = obj => {
    if (!this._mounted) return;

    this.setState(obj);
  };

  open = () => this.setState({ drawerOpened: true, mode: "add" });
  edit = field => this.setState({ drawerOpened: true, mode: "edit", field });
  close = () => this.safeSetState(this.initialState);

  create = field => {
    this.setState({ loading: true });

    api.post("/client/extrafields", field).then(response => {
      this.safeSetState({ loading: false });
      if (response.ok) {
        message.success(`Champ "${response.data.name}" ajouté.`);
        this.props.addExtrafield(response.data);
        this.close();
      }
    });
  };

  update = (field, id) => {
    this.setState({ loading: true });

    if (["choice", "multiple-choice"].indexOf(field.type) !== -1) {
      field.items = field.items
        .map((item, idx) => {
          if (this.state.field.items[idx]) {
            return {
              ...this.state.field.items[idx],
              name: item
            };
          } else {
            return {
              name: item
            };
          }
        })
        .filter(item => item);
    }

    api.patch(`/client/extrafields/${id}`, field).then(response => {
      this.safeSetState({ loading: false });
      if (response.ok) {
        if (response.data.error) {
          switch (response.data.error) {
            case "types-changed-but-used":
              notification.error({
                message: "Erreur!",
                description:
                  "Vous ne pouvez pas modifier le type du champ si il est utilisé."
              });
              break;
            case "used-item-deleted":
              notification.error({
                message: "Erreur!",
                description:
                  "Vous ne pouvez pas supprimé un choix qui est utilisé."
              });
              break;
            default:
              break;
          }
          this.close();
        } else {
          message.success(`Champ "${response.data.name}" modifié.`);
          this.close();
          this.props.updateExtrafield(id, response.data);
        }
      }
    });
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    return (
      <Prefetch
        ressources={[
          {
            url: "/client/extrafields",
            fetched: isExtrafieldsFetched,
            setter: setExtrafields
          }
        ]}
        fallback={<DelayedSpinner />}
      >
        <div className="extrafields pane">
          <Button type="primary" icon="plus" onClick={this.open}>
            Ajouter un nouveau champ
          </Button>
          <ExtrafieldList edit={this.edit} />
        </div>
        <Drawer
          destroyOnClose
          title={this.state.mode === "add" ? "Nouveau champ" : "Modification"}
          width={500}
          onClose={this.close}
          visible={this.state.drawerOpened}
        >
          <Form
            loading={this.state.loading}
            mode={this.state.mode}
            field={this.state.field}
            cancel={this.close}
            createField={this.create}
            updateField={this.update}
          />
        </Drawer>
      </Prefetch>
    );
  }
}

export default connect(
  null,
  { addExtrafield, updateExtrafield }
)(Extrafields);
