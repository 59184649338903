import React from "react";
import { connect } from "react-redux";

import api from "../lib/api";

class Prefetch extends React.PureComponent {
  static defaultProps = {
    fallback: null
  };

  state = {
    isFetching: false
  };

  allFetched = () =>
    this.props.ressources.reduce((acc, r) => acc && r.fetched, true);

  safeSetState = (obj, cb = () => {}) => {
    if (!this._mounted) return;

    this.setState(obj, cb);
  };

  async componentDidMount() {
    this._mounted = true;

    if (!this.state.isFetching && !this.allFetched()) {
      this.setState({ isFetching: true });

      const ressources = this.props.ressources.filter(r => !r.fetched);
      const results = await Promise.all(
        ressources.map(ressource => api.get(ressource.url))
      );

      this.safeSetState({ isFetching: false }, () =>
        results.map((result, index) =>
          this.props.dispatch(ressources[index].setter(result.data))
        )
      );
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    if (!this.allFetched()) return this.props.fallback;

    return this.props.children;
  }
}

const mapStateToProps = (state, ownProps) => ({
  ressources: ownProps.ressources.map(ressource => ({
    ...ressource,
    fetched: ressource.fetched(state)
  }))
});

export default connect(mapStateToProps)(Prefetch);
