import React from "react";
import { Modal, Button } from "antd";

const confirm = Modal.confirm;

export default function SideFormFooter({ cancel, loading = false, deleteOpt }) {
  function showDeleteConfirm() {
    confirm({
      title: deleteOpt.title,
      content: deleteOpt.content,
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteOpt.callback();
      }
    });
  }

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e9e9e9",
        padding: "10px 16px",
        background: "#fff",
        textAlign: "right"
      }}
    >
      <Button onClick={cancel} style={{ marginRight: 8 }}>
        Annuler
      </Button>
      {deleteOpt && (
        <Button
          onClick={showDeleteConfirm}
          type="danger"
          style={{ marginRight: 8 }}
        >
          Supprimer
        </Button>
      )}
      <Button htmlType="submit" type="primary" loading={loading}>
        Valider
      </Button>
    </div>
  );
}
