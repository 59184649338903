import React from "react";
import { notification, Button } from "antd";

import api from "../../../lib/api";

export default class AskForDelete extends React.PureComponent {
  state = {
    loading: false
  };

  forceDelete = () => {
    this.setState({ loading: true });

    api.delete(`/client/extrafields/${this.props.id}/force`).then(response => {
      this.setState({ loading: false });

      setTimeout(() => notification.close("ask-for-delete"), 250);
      if (response.ok) {
        setTimeout(() => this.props.deleteCallback(this.props.id), 250);
      }
    });
  };

  render() {
    return (
      <div>
        <p>Impossible de supprimer le champ car il est utilisé.</p>

        <p>
          Si vous supprimez ce champ toutes les données de ce champ seront
          supprimées définitivement.
        </p>
        <Button
          loading={this.state.loading}
          type="danger"
          onClick={this.forceDelete}
        >
          Forcer la suppression
        </Button>
      </div>
    );
  }
}
