import React from "react";
import { connect } from "react-redux";

import { getExtrafields, setExtrafields } from "../reducer";
import api from "../../../lib/api";

/**
 * @deprecated
 */
class PrefetchExtrafields extends React.Component {
  state = {
    isFetching: false
  };

  safeSetState = (obj, cb = () => {}) => {
    if (!this._mounted) return;

    this.setState(obj, cb);
  };

  componentDidMount() {
    this._mounted = true;

    if (!this.props.extrafields.fetched && !this.state.isFetching) {
      this.setState({
        isFetching: true
      });

      api.get("/client/extrafields").then(response => {
        this.safeSetState({ isFetching: false }, () =>
          this.props.setExtrafields(response.data)
        );
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    if (!this.props.extrafields.fetched) return null;

    return this.props.children;
  }
}

export default connect(
  state => ({ extrafields: getExtrafields(state) }),
  { setExtrafields }
)(PrefetchExtrafields);
