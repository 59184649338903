/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { connect } from "react-redux";
import { Tree, Empty, Alert, Drawer, message } from "antd";

import Form from "./tree-form";
import {
  getListsData,
  getListGroupsLength,
  getListGroups,
  addList,
  updateList,
  deleteList
} from "../reducer";
import api from "../../../lib/api";

const { TreeNode } = Tree;

class TreeDisplay extends React.PureComponent {
  initialState = {
    drawerOpened: false,
    loading: false
  };

  state = {
    ...this.initialState,
    parent: null,
    level: null,
    list: null
  };

  safeSetState = obj => {
    if (!this._mounted) return;

    this.setState(obj);
  };

  close = () => this.safeSetState(this.initialState);
  edit = (list, parent) =>
    this.setState({ drawerOpened: true, mode: "edit", list, parent });
  add = (level, parent) =>
    this.setState({ drawerOpened: true, mode: "add", level, parent });

  create = list => {
    this.setState({ loading: true });

    list.groupId = this.state.level.id;
    if (this.state.parent) list.parentId = this.state.parent.id;

    api.post("/lists", list).then(response => {
      if (response.ok) {
        message.success(
          `Élément "${response.data.name}" ajouté à l'arborescence.`
        );
        this.props.addList(response.data, this.state.parent);
      }
      this.close();
    });
  };

  update = list => {
    this.setState({ loading: true });

    api.patch(`/lists/${this.state.list.id}`, list).then(response => {
      if (response.ok) {
        this.props.updateList(response.data);
      }
      this.close();
    });
  };

  delete = () => {
    this.setState({ loading: true });

    api.delete(`/lists/${this.state.list.id}`).then(response => {
      if (response.ok) {
        message.success(
          `Élément "${this.state.list.name}" supprimé de l'arborescence.`
        );
        this.props.deleteList(
          this.state.list.id,
          this.state.parent ? this.state.parent.id : null
        );
      }
      this.close();
    });
  };

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  renderLevel(parentList, level) {
    if (!parentList.children) return null;

    level++;

    const { levels, levelsCount } = this.props;

    return parentList.children.map(list => (
      <TreeNode
        key={list.id}
        title={
          <span onClick={() => this.edit(list, parentList)}>{list.name}</span>
        }
      >
        {this.renderLevel(list, level)}
        {level < levelsCount && (
          <TreeNode
            title={
              <a onClick={() => this.add(levels[level], list)}>
                Ajouter {levels[level].label}
              </a>
            }
          />
        )}
      </TreeNode>
    ));
  }

  render() {
    const { levelsCount, levels } = this.props;

    return (
      <div className="tree">
        <h3>Arborescence</h3>

        {levelsCount === 0 ? (
          <Empty description="Aucun niveau, veuillez créer un niveau pour remplir l'arborescence" />
        ) : (
          <>
            <Alert
              type="info"
              banner
              message="Cliquer sur un élément pour le modifier/supprimer"
              style={{ marginBottom: "1rem" }}
            />
            <Tree selectable={false}>
              {this.props.lists.map(list => (
                <TreeNode
                  key={list.id}
                  title={
                    <span onClick={() => this.edit(list, null)}>
                      {list.name}
                    </span>
                  }
                >
                  {this.renderLevel(list, 1)}
                  {levelsCount > 1 && (
                    <TreeNode
                      title={
                        <a onClick={() => this.add(levels[1], list)}>
                          Ajouter {levels[1].label}
                        </a>
                      }
                    />
                  )}
                </TreeNode>
              ))}
              <TreeNode
                title={
                  <a onClick={() => this.add(levels[0], null)}>
                    Ajouter {levels[0].label}
                  </a>
                }
              />
            </Tree>
          </>
        )}
        <Drawer
          title={
            this.state.drawerOpened
              ? this.state.mode === "add"
                ? `Ajouter un élément (${this.state.level.label})`
                : `Modifier un élément (${this.state.list.name})`
              : ""
          }
          visible={this.state.drawerOpened}
          width={500}
          destroyOnClose
          onClose={this.close}
        >
          <Form
            mode={this.state.mode}
            list={this.state.list}
            loading={this.state.loading}
            cancel={this.close}
            create={this.create}
            update={this.update}
            delete={this.delete}
          />
        </Drawer>
      </div>
    );
  }
}

export default connect(
  state => ({
    lists: getListsData(state),
    levelsCount: getListGroupsLength(state),
    levels: getListGroups(state)
  }),
  { addList, updateList, deleteList }
)(TreeDisplay);
